@tailwind base;
@tailwind components;
@tailwind utilities;

/* tailwind.css or a custom CSS file */
@layer utilities {
  .scrollbar-thin {
    scrollbar-width: thin;
  }
  .scrollbar-thin::-webkit-scrollbar {
    width: 1px; /* Adjust width for thin scrollbar */
  }
  .scrollbar-thin::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.36); /* Scrollbar color */
    border-radius: 1px;
  }
  .scrollbar-thin::-webkit-scrollbar-track {
    background: transparent; /* Optional: track background */
  }
}

/* tailwind.css or main CSS file */
@layer utilities {
  @screen 2xl {
    .scrollbar-thin {
      scrollbar-width: thin;
    }
    .scrollbar-thin::-webkit-scrollbar {
      width: 1px;
    }
    .scrollbar-thin::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 2px;
    }
  }
}

body {
  margin: 0;
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  /* font-weight: <weight>; */
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}


